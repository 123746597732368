<template>
  <v-card outlined>
    <v-toolbar color="titleBar" dark flat dense>
      <v-toolbar-title>
        {{ title }}
      </v-toolbar-title>
      <v-spacer />
      <v-tooltip top v-if="!inCurrentZone">
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" :href="$config.mobileZonesUrl" icon target="_blank">
            <v-icon>mdi-earth-arrow-right</v-icon>
          </v-btn>
        </template>
        <span>{{ $t("zones information") }}</span>
      </v-tooltip>
    </v-toolbar>
    <v-card-text>
      <data-table-extended
        :headers="headers"
        :items="packages"
        :mobile-breakpoint="mbreakpoint"
        :expanded.sync="expanded"
        show-expand
        single-expand
        item-key="package_id"
        sort-by="package_id"
        :hide-default-footer="packages != null && packages.length <= 15"
        :footer-props="{
          showFirstLastPage: true,
          itemsPerPageOptions: [15, 50],
        }"
      >
        <template v-slot:item.costs_without_vat="{ item }">
          <div class="monospace text-no-wrap">
            {{
              item.gfo_variant
                ? formatMoney(item.gfo_variant.price_fix, "CHF")
                : "-"
            }}
          </div>
        </template>
        <template v-slot:item.costs_with_vat="{ item }">
          <div class="monospace text-no-wrap">
            {{
              item.gfo_variant
                ? formatMoney(item.gfo_variant.price_fix_incl, "CHF")
                : "-"
            }}
          </div>
        </template>
        <template v-slot:item.unlimited="{ item }">
          <boolean-value :value="item.unlimited" />
        </template>
        <template v-slot:item.roaming_zones="{ item }">
          {{ item.roaming_zones.join(", ") }}
        </template>
        <template v-slot:item.package_voice="{ item }">
          {{ item.package_voice ? formatDuration(item.package_voice) : "-" }}
        </template>
        <template v-slot:item.package_sms="{ item }">
          {{ item.package_sms ? item.package_sms : "-" }}
        </template>
        <template v-slot:item.package_data="{ item }">
          {{ item.package_data ? prettyBytes(item.package_data) : "-" }}
        </template>
        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length">
            <v-simple-table class="my-4" dense>
              <template v-slot:default>
                <tbody>
                  <tr>
                    <th>{{ $t("name") }}</th>
                    <td>{{ item.package_name }}</td>
                  </tr>
                  <tr v-if="$store.state.session.partnerType == 'WHO'">
                    <th>{{ $t("costs without VAT") }}</th>
                    <td>
                      {{
                        item.gfo_variant
                          ? formatMoney(item.gfo_variant.price_fix, "CHF")
                          : "-"
                      }}
                    </td>
                  </tr>
                  <tr v-if="$store.state.session.partnerType != 'WHO'">
                    <th>{{ $t("costs with VAT") }}</th>
                    <td>
                      {{
                        item.gfo_variant
                          ? formatMoney(item.gfo_variant.price_fix_incl, "CHF")
                          : "-"
                      }}
                    </td>
                  </tr>
                  <tr>
                    <th>{{ $t("recurring") }}</th>
                    <td>
                      <boolean-value :value="item.recurring" colored />
                    </td>
                  </tr>
                  <tr>
                    <th>{{ $t("unlimited") }}</th>
                    <td>
                      <boolean-value :value="item.unlimited" colored />
                    </td>
                  </tr>
                  <tr>
                    <th>{{ $t("roaming zones") }}</th>
                    <td>
                      {{ item.roaming_zones.join(", ") }}
                    </td>
                  </tr>
                  <tr>
                    <th>{{ $t("voice") }}</th>
                    <td>
                      {{
                        item.package_voice
                          ? formatDuration(item.package_voice)
                          : "-"
                      }}
                    </td>
                  </tr>
                  <tr>
                    <th>{{ $t("sms") }}</th>
                    <td>
                      {{ item.package_sms ? item.package_sms : "-" }}
                    </td>
                  </tr>
                  <tr>
                    <th>{{ $t("data") }}</th>
                    <td>
                      {{
                        item.package_data ? prettyBytes(item.package_data) : "-"
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2" class="text-right">
                      <v-btn
                        color="primary"
                        class="my-4"
                        @click="$emit('orderPackage', item)"
                        :disabled="loading || $store.getters.isReadOnly"
                        :loading="loading"
                        text
                      >
                        {{ $t("order for a fee") }}
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </td>
        </template>
      </data-table-extended>
    </v-card-text>
  </v-card>
</template>

<script>
import DataTableExtended from "@/components/basics/DataTableExtended";
import BooleanValue from "@/components/basics/BooleanValue";
import formatDateTime from "@/utils/mixins/formatDateTime";
import prettyBytes from "@/utils/mixins/prettyBytes";
import formatMoney from "@/utils/mixins/formatMoney";
import formatDuration from "@/utils/mixins/formatDuration";
import isMobile from "@/utils/mixins/isMobile";

export default {
  name: "MobilePhonePackagesAdd",
  components: {
    DataTableExtended,
    BooleanValue,
  },
  mixins: [formatDateTime, prettyBytes, formatMoney, formatDuration, isMobile],
  data: () => ({
    expanded: [],
  }),
  props: {
    packages: {
      type: Array,
      required: true,
    },
    inCurrentZone: {
      type: Boolean,
      required: false,
      default: false,
    },
    title: {
      type: String,
      required: true,
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    headers() {
      return [
        {
          text: this.$i18n.t("name"),
          value: "package_name",
        },
        {
          text: this.$i18n.t("costs with VAT"),
          value: "costs_with_vat",
          align: "right",
          hidden: this.$store.state.session.partnerType == "WHO",
        },
        {
          text: this.$i18n.t("costs without VAT"),
          value: "costs_without_vat",
          align: "right",
          hidden: this.$store.state.session.partnerType != "WHO",
        },
        {
          text: this.$i18n.t("roaming zones"),
          value: "roaming_zones",
        },
        {
          text: this.$i18n.t("unlimited"),
          value: "unlimited",
        },
        {
          text: this.$i18n.t("voice"),
          value: "package_voice",
          align: "right",
        },
        {
          text: this.$i18n.t("sms"),
          value: "package_sms",
          align: "right",
        },
        {
          text: this.$i18n.t("data"),
          value: "package_data",
          align: "right",
        },
        { text: "", value: "data-table-expand" },
      ];
    },
  },
  watch: {
    packages() {
      this.expanded = [];
    },
  },
};
</script>

<i18n>
{
  "en": {
    "order additional packages": "Order additional packages",
    "costs with VAT": "Costs with VAT",
    "costs without VAT": "Costs without VAT",
    "name": "Name",
    "voice": "Voice",
    "sms": "SMS",
    "data": "Data",
    "type": "Type",
    "recurring": "Recurring",
    "unlimited": "Unlimited",
    "roaming zones": "Zones",
    "order for a fee": "Order for a fee",
    "zones information": "Zones information"
  },
  "de": {
    "order additional packages": "Zusätzliche Pakete bestellen",
    "costs with VAT": "Kosten mit MwSt",
    "costs without VAT": "Kosten ohne MwSt",
    "name": "Name",
    "voice": "Sprache",
    "sms": "SMS",
    "data": "Daten",
    "type": "Typ",
    "recurring": "Wiederkehrend",
    "unlimited": "Unbeschränkt",
    "roaming zones": "Zonen",
    "order for a fee": "Kostenpflichtig bestellen",
    "zones information": "Zonen Informationen"
  }
}
</i18n>
