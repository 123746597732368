<template>
  <validation-observer ref="obs" v-slot="{ errors, invalid, validated }" slim>
    <v-card>
      <v-toolbar color="titleBar" dark flat dense>
        <v-toolbar-title>
          {{ $t("change subscription") }}
        </v-toolbar-title>
        <v-spacer />
      </v-toolbar>

      <v-progress-linear :active="loading" indeterminate />

      <v-card-text>
        <v-simple-table>
          <template v-slot:default>
            <tbody>
              <tr>
                <th>{{ $t("subject") }}</th>
                <td>
                  {{ subscription.subject }}
                </td>
              </tr>
              <tr>
                <th>{{ $t("status") }}</th>
                <td>
                  {{ $t(subscription.status_id) }}
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>

        <v-card class="mt-4" outlined>
          <v-toolbar color="titleBar" dark flat dense>
            <v-toolbar-title>
              {{ $t("SIM cards") }}
            </v-toolbar-title>
          </v-toolbar>

          <v-card-text>
            <v-row no-gutters align="center" justify="center">
              <!--
              <v-col cols="2">
                <span class="text-subtitle-1">{{ $t("phone number") }}</span>
              </v-col>
              -->
              <v-col cols="3">
                <span class="text-subtitle-1">{{ $t("ICCID") }}</span>
              </v-col>
              <v-col cols="3">
                <span class="text-subtitle-1">{{ $t("type") }}</span>
              </v-col>
              <v-col cols="6">
                <span class="text-subtitle-1">{{ $t("action") }}</span>
              </v-col>
            </v-row>

            <v-row no-gutters align="center" justify="center">
              <!--
              <v-col cols="2">
                <span class="monospace text-no-wrap">
                  {{ mobile ? mobile.phone_number : "-" }}
                </span>
              </v-col>
              -->
              <v-col cols="3">
                <span class="monospace text-no-wrap">
                  {{ mobile ? mobile.iccid : "-" }}
                </span>
              </v-col>
              <v-col cols="3">
                <v-chip outlined v-if="mobile && mobile.is_e_sim">
                  {{ $t("eSIM") }}
                  <v-icon right>$SimOutlineESim </v-icon>
                </v-chip>
                <v-chip outlined v-else-if="mobile">
                  {{ $t("phys. SIM") }}
                  <v-icon right> mdi-sim-outline </v-icon>
                </v-chip>
              </v-col>
              <v-col cols="6">
                <v-chip-group
                  v-model="primaryAction"
                  active-class="primary--text"
                >
                  <v-chip filter outlined v-show="mobile && mobile.is_e_sim">
                    {{ $t("change to phys. SIM") }}
                    <v-icon right> mdi-sim-outline </v-icon>
                  </v-chip>
                  <v-chip filter outlined v-show="mobile && !mobile.is_e_sim">
                    {{ $t("change to eSIM") }}
                    <v-icon right>$SimOutlineESim </v-icon>
                  </v-chip>
                </v-chip-group>
              </v-col>
            </v-row>

            <v-row no-gutters align="center" justify="center">
              <!--
              <v-col cols="2">
                <span class="monospace text-no-wrap">
                  {{ secondSim ? secondSim.number : "-" }}
                </span>
              </v-col>
              -->
              <v-col cols="3">
                <span class="monospace text-no-wrap">
                  {{ secondSim ? secondSim.iccid : "-" }}
                </span>
              </v-col>
              <v-col cols="3">
                <v-chip outlined v-if="secondSim && secondSim.is_e_sim">
                  {{ $t("eSIM") }}
                  <v-icon right>$SimOutlineESim </v-icon>
                </v-chip>
                <v-chip outlined v-else-if="secondSim">
                  {{ $t("phys. SIM") }}
                  <v-icon right> mdi-sim-outline </v-icon>
                </v-chip>
                <span class="monospace text-no-wrap" v-else>-</span>
              </v-col>
              <v-col cols="6">
                <v-chip-group
                  v-model="secondAction"
                  active-class="primary--text"
                  column
                >
                  <v-chip
                    filter
                    outlined
                    v-show="secondSim && secondSim.is_e_sim"
                  >
                    {{ $t("change to phys. SIM") }}
                    <v-icon right> mdi-sim-outline </v-icon>
                  </v-chip>
                  <v-chip
                    filter
                    outlined
                    v-show="secondSim && !secondSim.is_e_sim"
                  >
                    {{ $t("change to eSIM") }}
                    <v-icon right>$SimOutlineESim </v-icon>
                  </v-chip>
                  <v-chip filter outlined v-show="secondSim">
                    {{ $t("deactivate SIM") }}
                    <v-icon right> mdi-sim-off-outline </v-icon>
                  </v-chip>
                  <v-chip filter outlined v-show="!secondSim">
                    {{ $t("order phys. SIM") }}
                    <v-icon right> mdi-sim-outline </v-icon>
                  </v-chip>
                  <v-chip filter outlined v-show="!secondSim">
                    {{ $t("order eSIM") }}
                    <v-icon right> $SimOutlineESim </v-icon>
                  </v-chip>
                </v-chip-group>
              </v-col>
            </v-row>

            <v-row no-gutters align="center" justify="center">
              <!--
              <v-col cols="2">
                <span class="monospace text-no-wrap">
                  {{ thirdSim ? thirdSim.number : "-" }}
                </span>
              </v-col>
              -->
              <v-col cols="3">
                <span class="monospace text-no-wrap">
                  {{ thirdSim ? thirdSim.iccid : "-" }}
                </span>
              </v-col>
              <v-col cols="3">
                <v-chip outlined v-if="thirdSim && thirdSim.is_e_sim">
                  {{ $t("eSIM") }}
                  <v-icon right>$SimOutlineESim </v-icon>
                </v-chip>
                <v-chip outlined v-else-if="thirdSim">
                  {{ $t("phys. SIM") }}
                  <v-icon right> mdi-sim-outline </v-icon>
                </v-chip>
                <span class="monospace text-no-wrap" v-else>-</span>
              </v-col>
              <v-col cols="6">
                <v-chip-group
                  v-model="thirdAction"
                  active-class="primary--text"
                  column
                >
                  <v-chip
                    filter
                    outlined
                    v-show="thirdSim && thirdSim.is_e_sim"
                  >
                    {{ $t("change to phys. SIM") }}
                    <v-icon right> mdi-sim-outline </v-icon>
                  </v-chip>
                  <v-chip
                    filter
                    outlined
                    v-show="thirdSim && !thirdSim.is_e_sim"
                  >
                    {{ $t("change to eSIM") }}
                    <v-icon right>$SimOutlineESim </v-icon>
                  </v-chip>
                  <v-chip filter outlined v-show="thirdSim">
                    {{ $t("deactivate SIM") }}
                    <v-icon right> mdi-sim-off-outline </v-icon>
                  </v-chip>
                  <v-chip filter outlined v-show="!thirdSim">
                    {{ $t("order phys. SIM") }}
                    <v-icon right> mdi-sim-outline </v-icon>
                  </v-chip>
                  <v-chip filter outlined v-show="!thirdSim">
                    {{ $t("order eSIM") }}
                    <v-icon right> $SimOutlineESim </v-icon>
                  </v-chip>
                </v-chip-group>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>

        <v-card
          outlined
          class="mt-6"
          v-if="
            (!$store.getters.isWho || true) &&
            (countSim || countESim || primaryAction != null)
          "
        >
          <v-simple-table dense>
            <template v-slot:default>
              <thead>
                <tr>
                  <th colspan="2" class="text-overline">
                    {{ $t("costs") }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <!-- SIM/eSIM switch costs -->
                <tr
                  v-if="
                    catalogItem.gfo_variant &&
                    catalogItem.gfo_variant.esim_switch_variant &&
                    catalogItem.gfo_variant.esim_switch_variant
                      .price_fix_incl &&
                    primaryAction != null
                  "
                >
                  <td>
                    {{
                      catalogItem.gfo_variant.esim_switch_variant.subscription
                    }}
                    {{ $t("unique") }}:
                  </td>
                  <td>
                    {{
                      formatMoney(
                        catalogItem.gfo_variant.esim_switch_variant
                          .price_fix_incl,
                        "CHF"
                      )
                    }}
                  </td>
                </tr>
                <!-- new multi SIM monthly -->
                <tr
                  v-if="
                    catalogItem.gfo_variant &&
                    catalogItem.gfo_variant.multi_sim_variant &&
                    catalogItem.gfo_variant.multi_sim_variant.price_rec_incl &&
                    countSim
                  "
                >
                  <td>
                    {{ catalogItem.gfo_variant.multi_sim_variant.subscription }}
                    {{ $t("monthly") }}:
                  </td>
                  <td>
                    {{
                      countSim +
                      " x " +
                      formatMoney(
                        catalogItem.gfo_variant.multi_sim_variant
                          .price_rec_incl,
                        "CHF"
                      )
                    }}
                  </td>
                </tr>
                <!-- new multi SIM setup -->
                <tr
                  v-if="
                    catalogItem.gfo_variant &&
                    catalogItem.gfo_variant.multi_sim_variant &&
                    catalogItem.gfo_variant.multi_sim_variant.price_fix_incl &&
                    countSim
                  "
                >
                  <td>
                    {{ catalogItem.gfo_variant.multi_sim_variant.subscription }}
                    {{ $t("unique") }}:
                  </td>
                  <td>
                    {{
                      countSim +
                      " x " +
                      formatMoney(
                        catalogItem.gfo_variant.multi_sim_variant
                          .price_fix_incl,
                        "CHF"
                      )
                    }}
                  </td>
                </tr>
                <!-- new multi eSIM monthly -->
                <tr
                  v-if="
                    catalogItem.gfo_variant &&
                    catalogItem.gfo_variant.multi_esim_variant &&
                    catalogItem.gfo_variant.multi_esim_variant.price_rec_incl &&
                    countESim
                  "
                >
                  <td>
                    {{
                      catalogItem.gfo_variant.multi_esim_variant.subscription
                    }}
                    {{ $t("monthly") }}:
                  </td>
                  <td>
                    {{
                      countESim +
                      " x " +
                      formatMoney(
                        catalogItem.gfo_variant.multi_esim_variant
                          .price_rec_incl,
                        "CHF"
                      )
                    }}
                  </td>
                </tr>
                <!-- new multi eSIM setup -->
                <tr
                  v-if="
                    catalogItem.gfo_variant &&
                    catalogItem.gfo_variant.multi_esim_variant &&
                    catalogItem.gfo_variant.multi_esim_variant.price_fix_incl &&
                    countESim
                  "
                >
                  <td>
                    {{
                      catalogItem.gfo_variant.multi_esim_variant.subscription
                    }}
                    {{ $t("unique") }}:
                  </td>
                  <td>
                    {{
                      countESim +
                      " x " +
                      formatMoney(
                        catalogItem.gfo_variant.multi_esim_variant
                          .price_fix_incl,
                        "CHF"
                      )
                    }}
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>

        <v-alert
          class="ma-6"
          outlined
          type="error"
          border="left"
          v-if="
            catalogItem.gfo_variant &&
            catalogItem.gfo_variant.multi_esim_variant &&
            catalogItem.gfo_variant.multi_esim_variant.summary &&
            (countESim || primaryAction == 1)
          "
          v-html="catalogItem.gfo_variant.multi_esim_variant.summary"
        />
        <v-alert
          class="ma-6"
          outlined
          type="error"
          border="left"
          v-else-if="
            catalogItem.gfo_variant &&
            catalogItem.gfo_variant.multi_sim_variant &&
            catalogItem.gfo_variant.multi_sim_variant.summary &&
            (countSim || primaryAction == 0)
          "
          v-html="catalogItem.gfo_variant.multi_sim_variant.summary"
        />

        <v-card class="mt-4" outlined>
          <v-toolbar color="titleBar" dark flat dense>
            <v-spacer />
            <v-tooltip top v-if="deliveryAddressDeleted">
              <template v-slot:activator="{ on }">
                <v-btn
                  v-on="on"
                  @click="
                    setDefaultDeliveryAddress();
                    deliveryAddressDeleted = false;
                  "
                  :disabled="loading"
                  icon
                >
                  <v-icon>mdi-restore</v-icon>
                </v-btn>
              </template>
              <span>{{ $t("set default delivery address") }}</span>
            </v-tooltip>

            <v-tooltip top v-if="!deliveryAddressDeleted">
              <template v-slot:activator="{ on }">
                <v-btn
                  v-on="on"
                  @click="
                    deliveryAddress = {};
                    deliveryAddressDeleted = true;
                  "
                  :disabled="loading || !overwriteDeliveryAddress"
                  icon
                >
                  <v-icon>mdi-delete-sweep-outline</v-icon>
                </v-btn>
              </template>
              <span>{{ $t("delete delivery address") }}</span>
            </v-tooltip>

            <v-tooltip
              top
              v-if="$store.getters.isStaff || $store.getters.isWho"
            >
              <template v-slot:activator="{ on }">
                <v-btn
                  v-on="on"
                  @click="previewReceipt"
                  :disabled="loading || invalid"
                  :loading="loadingPreview"
                  icon
                  :color="!$store.getters.isWho ? 'staff' : ''"
                >
                  <v-icon>$PdfDownload</v-icon>
                </v-btn>
              </template>
              <span>{{ $t("preview delivery receipt") }}</span>
            </v-tooltip>

            <v-dialog
              v-model="editReceipt"
              width="80%"
              v-if="$store.getters.isStaff || $store.getters.isWho"
            >
              <template v-slot:activator="{ on }">
                <v-tooltip top v-on="on">
                  <template v-slot:activator="{ on }">
                    <v-btn
                      v-on="on"
                      @click="
                        editReceipt = true;
                        getReceipt();
                      "
                      :disabled="loading || invalid"
                      :loading="loadingReceipt"
                      icon
                      :color="!$store.getters.isWho ? 'staff' : ''"
                    >
                      <v-icon>mdi-file-document-edit</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t("edit delivery receipt") }}</span>
                </v-tooltip>
              </template>
              <hardware-shop-delivery-receipt
                :receipt="deliveryReceipt"
                :loading="loadingReceipt"
                @receiptCanceled="editReceipt = false"
                @receiptReset="
                  deliveryReceipt = '';
                  getReceipt();
                "
                @receiptSaved="
                  deliveryReceipt = $event;
                  editReceipt = false;
                "
              />
            </v-dialog>
          </v-toolbar>

          <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-switch
                  v-model="overwriteDeliveryAddress"
                  :label="$t('change delivery address')"
                />
              </v-col>
            </v-row>

            <v-card class="px-6" outlined>
              <address-names
                v-model="deliveryAddress"
                company-max-length="100"
                :company-rules="deliveryAddress.last_name ? '' : 'required'"
                first-name-max-length="10"
                last-name-max-length="100"
                :last-name-rules="deliveryAddress.company ? '' : 'required'"
                saltutation-max-length="100"
                name-prefix="delivery_address."
                :disabled="!overwriteDeliveryAddress"
              />
            </v-card>

            <v-card outlined class="px-6 mt-4">
              <address-postal
                v-model="deliveryAddress"
                additional-max-length="255"
                zip-max-length="10"
                zip-name="zip_code"
                zip-rules="required"
                city-max-length="100"
                city-rules="required"
                street-max-length="100"
                street-rules="required"
                street-number-max-length="10"
                street-number-rules="required"
                name-prefix="delivery_address."
                :disabled="!overwriteDeliveryAddress"
              />
            </v-card>

            <v-card class="px-6 mt-4 pt-6" outlined>
              <v-row>
                <v-col cols="12">
                  <email-list-input
                    v-model="email"
                    name="email"
                    :label="$t('email')"
                    :hint="$t('email for order confirmation')"
                    rules="max:1024"
                  />
                </v-col>
              </v-row>
              <v-row v-if="$store.getters.isStaff || $store.getters.isWho">
                <v-col cols="12">
                  <validation-provider
                    vid="product_description"
                    :name="$t('product description')"
                    rules="max:100"
                    v-slot="{ errors, valid, dirty, classes }"
                  >
                    <v-text-field
                      v-model="productDescription"
                      name="product_description"
                      :label="$t('product description')"
                      :error-messages="errors"
                      :success="dirty && valid"
                      counter="100"
                      :clearable="!isMobile"
                      :class="{
                        ...classes,
                        'staff--text': !$store.getters.isWho,
                      }"
                    />
                  </validation-provider>
                </v-col>
              </v-row>
              <v-row v-if="$store.getters.isStaff || $store.getters.isWho">
                <v-col cols="12">
                  <validation-provider
                    vid="note"
                    :name="$t('order note')"
                    rules="max:1024"
                    v-slot="{ errors, valid, dirty, classes }"
                  >
                    <v-textarea
                      v-model="orderNote"
                      name="order_note"
                      :label="$t('order note')"
                      :error-messages="errors"
                      :success="dirty && valid"
                      :class="{
                        ...classes,
                        'staff--text': !$store.getters.isWho,
                      }"
                      :clearable="!isMobile"
                      maxlength="1024"
                      counter
                      rows="1"
                      auto-grow
                    />
                  </validation-provider>
                </v-col>
              </v-row>
            </v-card>
          </v-card-text>
        </v-card>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn @click="$emit('cancel')" text>
          {{ $t("cancel") }}
        </v-btn>
        <v-btn
          text
          color="primary"
          :loading="loading"
          :disabled="
            loading ||
            !mobile ||
            (primaryAction == null &&
              secondAction == null &&
              thirdAction == null)
          "
          @click="order"
        >
          <v-icon left>mdi-cart</v-icon>
          {{ $t("order") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </validation-observer>
</template>

<script>
import AddressPostal from "@/components/basics/AddressPostal";
import AddressNames from "@/components/basics/AddressNames";
import AddressContacts from "@/components/basics/AddressContacts";
import LanguageSelect from "@/components/basics/LanguageSelect";
import CountrySelect from "@/components/basics/CountrySelect";
import DatePicker from "@/components/basics/DatePicker";
import TimePicker from "@/components/basics/TimePicker";
import BooleanValue from "@/components/basics/BooleanValue";
import formatDateTime from "@/utils/mixins/formatDateTime";
import formatDate from "@/utils/mixins/formatDate";
import formatPhoneNumber from "@/utils/mixins/formatPhoneNumber";
import formatTime from "@/utils/mixins/formatTime";
import formatMoney from "@/utils/mixins/formatMoney";
import EmailListInput from "@/components/basics/EmailListInput";
import showErrors from "@/utils/mixins/showErrors";
import isMobile from "@/utils/mixins/isMobile";
import isTeldasNoneWorkingDay from "@/utils/mixins/teldasNoneWorkingDays";
import downloadFile from "@/utils/mixins/downloadFile";
import PhoneSelectNumber from "./PhoneSelectNumber";
import HardwareShopDeliveryReceipt from "@/components/orders/hardware/HardwareShopDeliveryReceipt";

export default {
  name: "MobilePhoneChange",
  components: {
    DatePicker,
    TimePicker,
    BooleanValue,
    EmailListInput,
    AddressPostal,
    AddressNames,
    AddressContacts,
    LanguageSelect,
    CountrySelect,
    PhoneSelectNumber,
    HardwareShopDeliveryReceipt,
  },
  mixins: [
    showErrors,
    formatDateTime,
    formatDate,
    formatTime,
    formatMoney,
    formatPhoneNumber,
    isMobile,
    downloadFile,
    isTeldasNoneWorkingDay,
  ],
  props: {
    productId: {
      type: [Number, String],
      required: true,
    },
    subscription: {
      type: Object,
      required: true,
    },
    catalogItem: {
      type: Object,
      required: true,
    },
    mobile: {
      type: Object,
      required: false,
      default: null,
    },
    secondSim: {
      type: Object,
      required: false,
      default: null,
    },
    thirdSim: {
      type: Object,
      required: false,
      default: null,
    },
  },
  data: () => ({
    deliveryDate: null,
    email: null,
    productDescription: null,
    orderNote: null,
    primaryAction: null,
    secondAction: null,
    thirdAction: null,
    deliveryAddress: {
      empty: true,
    },
    deliveryReceipt: null,
    loadingOrder: false,
    loadingValidate: false,
    loadingPreview: false,
    loadingReceipt: false,
    editReceipt: false,
    deliveryAddressDeleted: false,
    overwriteDeliveryAddress: false,
  }),
  computed: {
    loading() {
      return (
        this.loadingOrder ||
        this.loadingValidate ||
        this.loadingReceipt ||
        this.loadingPreview
      );
    },
    countSim() {
      var count = 0;
      if (this.secondAction == 0) count += 1;
      else if (this.secondAction == 3) count += 1;
      if (this.thirdAction == 0) count += 1;
      else if (this.thirdAction == 3) count += 1;
      return count;
    },
    countESim() {
      var count = 0;
      if (this.secondAction == 1) count += 1;
      else if (this.secondAction == 4) count += 1;
      if (this.thirdAction == 1) count += 1;
      else if (this.thirdAction == 4) count += 1;
      return count;
    },
  },
  watch: {
    deliveryAddress: {
      immediate: true,
      handler(value) {
        if (value && value.empty) this.setDefaultDeliveryAddress();
      },
    },
    email: {
      immediate: true,
      handler(value) {
        if (value == null) this.email = this.$store.getters.deliveryEmail;
      },
    },
  },
  methods: {
    allowedDatesDelivery(date) {
      return this.isWorkingDate(date);
    },
    setDefaultDeliveryAddress() {
      this.deliveryAddress = {
        ...this.$store.getters.defaultDeliveryAddress,
      };
    },
    isWorkingDate(date) {
      // require at least 5 full days in advance
      let nextDate = this.addWorkingDays(new Date(), 5);
      let latestDate = this.$moment(new Date())
        .add(12, "months")
        .add(1, "days")
        .toDate();
      return (
        this.$moment(date).isAfter(nextDate) &&
        this.$moment(date).isBefore(latestDate) &&
        !this.isTeldasNoneWorkingDay(date)
      );
    },
    getOrderData() {
      return {
        product_id: null,
        delivery_date: null,
        email: null,
        product_description: null,
        note: null,
        primary_sim_type:
          this.primaryAction == 0
            ? "sim"
            : this.primaryAction == 1
            ? "esim"
            : null,
        second_sim_id: this.secondSim ? this.secondSim.iccid : null,
        second_sim_type:
          this.secondAction == 0
            ? "sim"
            : this.secondAction == 1
            ? "esim"
            : this.secondAction == 2
            ? "delete"
            : this.secondAction == 3
            ? "sim"
            : this.secondAction == 4
            ? "esim"
            : null,
        third_sim_id: this.thirdSim ? this.thirdSim.iccid : null,
        third_sim_type:
          this.thirdAction == 0
            ? "sim"
            : this.thirdAction == 1
            ? "esim"
            : this.thirdAction == 2
            ? "delete"
            : this.thirdAction == 3
            ? "sim"
            : this.thirdAction == 4
            ? "esim"
            : null,
        delivery_address: null,
        delivery_receipt: null,
      };
    },
    previewReceipt() {
      var that = this;
      this.loadingPreview = true;
      var data = this.getOrderData();
      this.$http
        .put(
          "services/phone/" +
            this.productId +
            "/mobiles/" +
            this.mobile.id +
            "/change",
          data
        )
        .then((response) => {
          console.log(">>> ", response.data);
          try {
            this.downloadFile(
              "services/phone/" +
                this.productId +
                "/mobiles/" +
                this.mobile.id +
                "/change?format=pdf",
              "put",
              data,
              this.$i18n.t("delivery-receipt.pdf"),
              null,
              function () {
                that.loadingPreview = false;
              }
            );
          } catch (error) {
            that.loadingPreview = false;
            console.log(error);
          }
        })
        .catch((error) => {
          that.loadingPreview = false;
          that.$refs.obs.setErrors(error.data);
          that.showErrors(that.$refs.obs.getUnresolvedErrors(error));
        });
    },
    getReceipt() {
      if (this.deliveryReceipt == null || this.deliveryReceipt.trim() == "") {
        var that = this;
        var data = this.getOrderData();
        this.loadingReceipt = true;
        this.$http
          .put(
            "services/phone/" +
              this.productId +
              "/mobiles/" +
              this.mobile.id +
              "/change",
            data
          )
          .then((response) => {
            that.deliveryReceipt = response.data.delivery_receipt;
          })
          .catch((error) => {
            that.$refs.obs.setErrors(error.data);
            that.showErrors(that.$refs.obs.getUnresolvedErrors(error));
          })
          .finally(function () {
            that.loadingReceipt = false;
          });
      }
    },
    order() {
      var that = this;
      this.loadingOrder = true;
      var data = this.getOrderData();
      this.$http
        .post(
          "services/phone/" +
            this.productId +
            "/mobiles/" +
            this.mobile.id +
            "/change",
          data
        )
        .then((response) => {
          that.$snotify.success(that.$i18n.t("notifyMsg"));
          that.$router.push({
            name: "tasks",
            params: { selectedTaskId: response.data.id },
          });
        })
        .catch((error) => {
          if (error.status != 404)
            that.$store.commit("setSystemError", {
              msg: error.message,
              title: error.title,
            });
        })
        .finally(function () {
          that.loadingOrder = false;
        });
    },
  },
};
</script>

<i18n>
{
  "en": {
    "subject": "Subject",
    "status": "Status",
    "phone number": "Phone number",
    "SIM cards": "SIM cards",
    "ICCID": "ICCID",
    "type": "Type",
    "action": "Action",
    "change subscription": "Change subscription",
    "change to phys. SIM": "Change to phys. SIM",
    "change to eSIM": "Change to eSIM",
    "deactivate SIM": "Deactivate SIM",
    "order phys. SIM": "Order phys. SIM",
    "order eSIM": "Order eSIM",
    "cancel": "Cancel",
    "eSIM": "eSIM",
    "order": "Order",
    "order note": "Order note",
    "product description": "Product description",
    "edit delivery receipt": "edit delivery receipt",
    "change delivery address": "change delivery address",
    "delete delivery address": "delete delivery address",
    "preview delivery receipt": "preview delivery receipt",
    "delivery date": "Delivery date",
    "email": "Email",
    "email for order confirmation": "email for order confirmation",
    "delivery-receipt.pdf": "delivery-receipt.pdf",
    "notifyMsg": "Your order has been successfully committed.",
    "phys. SIM": "phys. SIM",
    "eSIM": "eSIM",
    "NEW": "New",
    "ACTIVATED": "Activated",
    "ACTIVATIONPENDING": "Activation pending",
    "DEACTIVATED": "Deactivated",
    "DEACTIVATIONPENDING": "Deactivation pending",
    "CANCELLED": "Cancelled",
    "CANCELLATIONPENDING": "Canceltion pending",
    "CLOSINGBILLPENDING": "Closing bill pending",
    "SETUPPENDING": "Setup pending",
    "CHANGEPENDING": "Change pending",
    "monthly": "monthly",
    "unique": "unique"
  },
  "de": {
    "subject": "Abonnement",
    "status": "Status",
    "phone number": "Telefonnummer",
    "SIM cards": "SIM Karten",
    "ICCID": "ICCID",
    "type": "Typ",
    "action": "Aktion",
    "change subscription": "Abonnement ändern",
    "change to phys. SIM": "Wechsel auf phys. SIM",
    "change to eSIM": "Wechsel auf eSIM",
    "deactivate SIM": "SIM deaktivieren",
    "order phys. SIM": "phys. SIM bestellen",
    "order eSIM": "eSIM bestellen",
    "cancel": "Abbrechen",
    "eSIM": "eSIM",
    "order": "Bestellen",
     "order note": "Bestellnotiz",
    "product description": "Produktbeschreibung",
    "edit delivery receipt": "Lieferschein bearbeiten",
    "change delivery address": "Lieferadresse ändern",
    "delete delivery address": "Lieferadresse löschen",
    "preview delivery receipt": "Vorschau Lieferschein",
    "delivery date": "Lieferdatum",
    "email": "Email",
    "email for order confirmation": "Email für Bestellbestätigung",
    "delivery-receipt.pdf": "Lieferschein.pdf",
    "notifyMsg": "Ihre Bestellung wurde erfolgreich übermittelt.",
    "phys. SIM": "phys. SIM",
    "eSIM": "eSIM",
    "NEW": "Neu",
    "ACTIVATED": "Aktiviert",
    "ACTIVATIONPENDING": "Aktivierung in Bearbeitung",
    "DEACTIVATED": "Deaktiviert",
    "DEACTIVATIONPENDING": "Deaktivierung in Bearbeitung",
    "CANCELLED": "Gekündigt",
    "CANCELLATIONPENDING": "Kündigung in Bearbeitung",
    "CLOSINGBILLPENDING": "Rechnungsabschluss in Bearbeitung",
    "SETUPPENDING": "Einrichtung in Bearbeitung",
    "CHANGEPENDING": "Änderung in Bearbeitung",
    "monthly": "monatlich",
    "unique": "einmalig"
  }
}
</i18n>
